import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import Voxel from './Voxel';

const VoxelGrid = ({ size }) => {
  const groupRef = useRef();

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.x += 0.005;
      groupRef.current.rotation.y += 0.005;
    }
  });

  const voxels = [];
  const offset = size / 2 - 0.5;
  for (let x = 0; x < size; x++) {
    for (let y = 0; y < size; y++) {
      for (let z = 0; z < size; z++) {
        voxels.push(
          <Voxel
            key={`${x}-${y}-${z}`}
            position={[x - offset, y - offset, z - offset]}
          />,
        );
      }
    }
  }

  return <group ref={groupRef}>{voxels}</group>;
};

export default VoxelGrid;
