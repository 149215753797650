import React from 'react';
import { Canvas } from '@react-three/fiber';
import VoxelGrid from '../Components/VoxelGrid';

const MainScene = () => {
  return (
    <Canvas
      style={{ height: '100vh', background: 'black' }}
      camera={{ position: [0, 0, 20] }}>
      <ambientLight intensity={2} />
      <directionalLight position={[5, 5, 5]} intensity={2} />
      <VoxelGrid size={10} />
    </Canvas>
  );
};

export default MainScene;
