import React from 'react';
import * as THREE from 'three';

const Voxel = ({ position }) => {
  return (
    <mesh position={position}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color='white' />
      <lineSegments>
        <edgesGeometry args={[new THREE.BoxGeometry(1, 1, 1)]} />
        <lineBasicMaterial color='gray' transparent opacity={0.5} />
      </lineSegments>
    </mesh>
  );
};

export default Voxel;
