import './Styles/main.css';

import MainScene from './Scenes/MainScene';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className='app-container'>
      <MainScene />
      <Box className='overlay'>
        <Typography
          color='white'
          sx={{ fontFamily: 'Tiny5', fontSize: '2rem', textAlign: 'center' }}>
          Coming Soon, on September 2024
        </Typography>
        <Typography
          component='h1'
          sx={{
            fontFamily: 'Tiny5',
            fontSize: isMobile ? '5rem' : '10rem',
            paddingBottom: isMobile ? '5rem' : '0',
            textAlign: 'center',
          }}>
          CUB'Z
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
